body {
  background-color: #f1f4f9 !important;
}

.check_box_custom_class {
  label {
    &:before,
    &:after {
      right: -23px !important;
      top: 0.225rem !important;
    }
  }
}
.check_box_custom_class .custom-control-label::before {
  right: -10px !important;
  left: unset !important;
}
body {
  overflow-x: hidden;
}

.custom_transitions {
  animation: slideDown 3s ease-in-out forwards;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
thead.thead-light button.ascending::after {
  content: "👇";
  display: inline-block;
  margin-left: 1em;
}

thead.thead-light button.descending::after {
  content: "☝️";
  display: inline-block;
  margin-left: 1em;
}
thead.thead-light button {
  border: 0;
  color: #8898aa;
  background: transparent;
}
.bg-gradient-secondaryone {
  background: linear-gradient(87deg, #7e2594 0, #201c2e 100%) !important;
}

.dispatcher_table {
  width: 100%;
  min-height: 135px;
  td:nth-child(1),
  th:nth-child(1) {
    position: sticky;
    left: 0;
    background-color: #fff;
    z-index: 2;
  }

  td:nth-child(2),
  th:nth-child(2) {
    position: sticky;
    left: 64px;
    background-color: #fff;
    z-index: 2;
    &::after {
      box-shadow: "inset 10px 0 8px -8px rgb(5 5 5 / 6%)";
    }
    @media screen and (min-width: 1100px) {
      left: 77px;
    }
  }
}
.sidebar_hide {
  transition: all 3s ease-in-out;
  display: flex;
  z-index: 1050;
  @media screen and (min-width: 768px) {
    display: none;
  }
}
.sidebar_close_btn button {
  @media screen and (min-width: 768px) {
    width: 29px;
    height: 20px;
    position: relative;
    cursor: pointer;
    display: inline-block;
    padding: 0;
    position: absolute;
    right: -15px;
    top: 3px;
    span {
      display: block;
      position: absolute;
      width: 100%;
      height: 2px;
      border-radius: 2px;
      opacity: 1;
      background: #283448;
      &:nth-child(1) {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
      }
      &:nth-child(2) {
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
    }
  }
}
.select2-container .select2-selection--single {
  box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
  border: 0;
  transition: box-shadow 0.15s ease;
  display: block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.select2-container {
  width: 100% !important;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  top: 9px !important;
}
.select2-container--default
  .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #c50074 !important;
}

.dispatcher_table .custom-control-label::before {
  left: -1.5rem;
  @media screen and (min-width: 992px) {
    left: -3rem;
  }
}
.dispatcher_table .custom-control-label::after {
  left: -1.5rem;
  @media screen and (min-width: 992px) {
    left: -3rem;
  }
}
.custom-control.custom-checkbox {
  padding-left: 2rem !important;
  label::after {
    left: -2.5rem !important;
    @media screen and (min-width: 575px) {
      left: -2rem !important;
    }
  }
  label::before {
    left: -2.5rem !important;
    @media screen and (min-width: 575px) {
      left: -2rem !important;
    }
  }
}
.custom-control.custom-checkbox.abc {
  padding-left: 2rem !important;
  label::after {
    left: -2.5rem !important;
  }
  label::before {
    left: -2.5rem !important;
  }
}

.table_padding {
  padding: ".5rem .7rem";
}
.dispatcher_loader_class {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(128, 128, 128, 0.829);
  z-index: 1080;
}
.react-tooltip {
  @media screen and (max-width: 575px) {
    display: none;
  }
}
.auto-placeholder-main .special {
  z-index: 2;
  position: absolute;
  width: calc(100% - 1rem);

  @media screen and (min-width: 575px) {
    width: calc(100% - 2rem);
  }
}
